<template>
    <div>
      <log-passport-report></log-passport-report>
    </div>
  </template>
  
  <script>
  import LogPassportReport from "@/components/ecologist/reports/passport/LogPassportReport";
  export default {
    name: "LogPassportView",
    components: { LogPassportReport },
  };
  </script>
  
  <style scoped></style>