<template>
    <div>
      <h2>Журнал регистрации сопроводительных паспортов</h2>
      
      <el-form
        ref="formedForm"
        label-position="top"
        label-width="120px"
      >
      <el-row :gutter="10">
          <el-form-item prop="startDate" label="Период">
            <el-col :span="12">
              <el-date-picker
                style="width: 100%"
                value-format="yyyy-MM-dd"
                v-model="passport.startDate"
                type="date"
              >
              </el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item prop="endDate" label="">
            <el-col :span="12">
              <el-date-picker
                style="width: 100%"
                value-format="yyyy-MM-dd"
                v-model="passport.endDate"
                type="date"
              >
              </el-date-picker>
            </el-col>
          </el-form-item>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item
              prop="organizationInfo"
              label="Объект / Филиал / Подразделение / Участок"
            >
              <el-select
                style="width: 100%"
                clearable
                value-key="id"
                v-model="passport.organizationUnit"
                filterable
                placeholder="Выберите"
              >
                <el-option
                  v-for="item in organizationHierarchyByType"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      <el-row>
        <el-col :span="4">
          <el-form-item label="Тип файла">
            <el-select
              clearable
              v-model="passport.fileType"
              placeholder="Формат отчета"
            >
              <el-option
                v-for="item in fileTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <div style="padding-bottom: 10px; height: 40px;">
            <br>
          </div>
          <el-button @click="downloadReport" type="primary" style="margin-left: 10px;"
            >Сформировать журнал регистрации</el-button>
        </el-col>
      </el-row>
      </el-form>
    </div>
  </template>
  
  <script>
  //import SearchBlock from "@/components/searchBlock";
  
  import { mapGetters } from "vuex";
  export default {
    name: "PassportReport",
    //components: { SearchBlock },
    data() {
      return {
        passports:null,
        passport: {
          organizationUnit:null,
          fileType: ".pdf",
          num:null,
        },
        fileTypes: [
          {
            value: ".pdf",
            label: ".pdf",
          },
          {
            value: ".xls",
            label: ".xls",
          },
        ],
      };
    },
    computed: {
      ...mapGetters({
        organizationHierarchyByType: "GETORGANIZATIHIERARCHYBYTYPE",
      }),
    },
  
    methods: {
        
      getOrganizationHierarchyByType() {
        this.$store.dispatch("getOrganizationHierarchyByType", {
          type: 3,
        });
      },

    downloadReport() {
      this.$store.dispatch("downloadLogPassport", this.passport);
    },
      
    },
  
  
    mounted() {
      this.getOrganizationHierarchyByType();
    },
  };
  </script>
  
  <style scoped></style>
  